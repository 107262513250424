import React, { useState } from 'react';
import './choreChart.css';

const ChoreChart = () => {
  const chores = [
    { id: 1, name: 'Feed Bro (Morning) $.50', value: 0.50, completed: false },
    { id: 2, name: 'Feed Bro (Evening) $.50', value: 0.50, completed: false },
    { id: 3, name: 'Feed Winston (Morning) $.50', value: 0.50, completed: false },
    { id: 4, name: 'Feed Winston (Evening) $.50', value: 0.50, completed: false },
    { id: 5, name: 'Help Clean the Table After Dinner $1', value: 1, completed: false },
    { id: 6, name: 'Mop the Floor $5', value: 5, completed: false },
  ];

  const [choresData, setChoresData] = useState(
    Object.fromEntries(
      ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => [
        day,
        chores.map((chore) => ({ ...chore, completed: false })),
      ])
    )
  );

  const [earnings, setEarnings] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    // Simulating login validation
    if (username === 'mila' && password === 'delpadre') {
      setIsLoggedIn(true);
    } else {
      alert('Invalid username or password');
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    setPassword('');
  };

  const handleToggle = (day, id) => {
    const currentDate = new Date();
    const currentDayIndex = currentDate.getDay();
    const dayIndex = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(day);

    if (dayIndex > currentDayIndex) {
      return; // Exit early if it's a future day
    }

    if (dayIndex < currentDayIndex) {
      return; // Exit early if it's a previous day
    }

    const updatedChoresData = {
      ...choresData,
      [day]: choresData[day].map((chore) => {
        if (chore.id === id) {
          return { ...chore, completed: !chore.completed };
        }
        return chore;
      }),
    };

    const chore = updatedChoresData[day].find((chore) => chore.id === id);
    const amountEarned = chore.completed ? chore.value : -chore.value;

    setChoresData(updatedChoresData);
    setEarnings((prevEarnings) => prevEarnings + amountEarned);
  };

  const today = new Date();
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  const formattedDate = today.toLocaleDateString(undefined, options);

  const generateEmailContent = () => {
    let emailText = `Mila's Chore Chart Submission - ${formattedDate}\n\n`;

    Object.entries(choresData).forEach(([day, dayChores]) => {
      emailText += `${day}:\n`;
      let dailyTotal = 0; // Variable to store the daily total
      dayChores.forEach((chore) => {
        if (chore.completed) {
          emailText += `- ${chore.name}\n`;
          dailyTotal += chore.value; // Add chore value to the daily total
        }
      });
      emailText += `Daily total: $${dailyTotal.toFixed(2)}\n\n`; // Include the daily total in the email
    });

    emailText += `Total earnings for the week: $${earnings}`;

    const mailToLink = `mailto:chores@delpadre.xyz?subject=Mila's Chore Chart Submission&body=${encodeURIComponent(
      emailText
    )}`;
    window.location.href = mailToLink;
  };

  return (
    <div className="chore-chart">
      {!isLoggedIn ? (
        <form className="login-form" onSubmit={handleLogin}>
          <h2 className="login-heading">Login</h2>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">
            Log In
          </button>
        </form>
      ) : (
        <>
          <h2 className="chart-heading">Mila's Chore Chart</h2>
          <h3 className="date">{formattedDate}</h3>
          <div className="days-container">
            {Object.entries(choresData).map(([day, dayChores]) => (
              <div key={day} className="day">
                <h3>{day}</h3>
                <ul className="chore-list">
                  {dayChores.map((chore) => (
                    <li key={chore.id} className={chore.completed ? 'completed' : ''}>
                      <label>
                        <input
                          type="checkbox"
                          checked={chore.completed}
                          disabled={new Date().getDay() !== ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(day)}
                          onChange={() => handleToggle(day, chore.id)}
                        />
                        {chore.name}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <h3 className="earnings">Total earnings for the week: ${earnings}</h3>
          <button className="submit-button" onClick={generateEmailContent}>
            Submit
          </button>
          <button className="logout-button" onClick={handleLogout}>
            Log Out
          </button>
        </>
      )}
    </div>
  );
};

export default ChoreChart;
